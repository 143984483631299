import React from 'react';
import i18n from 'i18next';

const LangSelect = ({...otherProps}) => {
  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header {...otherProps}>
      <ul style={{ display: 'flex', listStyle: 'none', cursor: "pointer", marginBlockStart:0, marginBlockEnd:0, padding:0 }}>
        <li style={{ margin: '0 10px' }} onClick={() => changeLanguage('en')}>English</li>
        <li style={{ margin: '0 10px' }} onClick={() => changeLanguage('bengali')}>বাংলা</li>
        <li style={{ margin: '0 10px' }} onClick={() => changeLanguage('hindi')}>हिंदी</li>
        <li style={{ margin: '0 10px' }} onClick={() => changeLanguage('assamese')}>অসমীয়া</li>
        <li style={{ margin: '0 10px' }} onClick={() => changeLanguage('oriya')}>ଓଡ଼ିଆ</li>
      </ul>
    </header>
  );
};

export default LangSelect;